<!--
 * @Author: fangting fangting@whty.com.cn
 * @Date: 2022-12-12 15:08:33
 * @LastEditors: fangting fangting@whty.com.cn
 * @LastEditTime: 2023-01-09 11:17:46
 * @FilePath: \web\src\views\enterprise\modules\addEnterprise.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
     <div class="edit_insurance">
    <div class="title">编辑企业管理</div>
    <!-- 企业名称 纳税人识别号 公司地址 详细地址 公司电话 开户银行 开户账号 联系人 联系方式 电子邮箱 -->
     <!-- companyName taxId address detailAddress companyPhone bankName bankAccountNo linkMan linkPhone email  -->
    <div class="desCla">
      <el-form :model="form" ref="form" label-width="10rem" class="ruleForm" :rules="rules">
        <el-form-item label="企业名称" prop="companyId">
          <el-select v-model="form.companyId" placeholder="请选择企业名称" @change="selectCompany" filterable disabled>
            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
          <!-- <span>(创建账号: {{createAccount}})</span> -->
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="taxId"> 
          <el-input v-model="form.taxId" placeholder="请输入纳税人识别号" :disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="address">
          <el-cascader
              size="large"
              :options="addressOptions"
              :disabled="disabled"
              v-model="form.address"
              :placeholder="'省、市、区'"
              @change="addressChange">
            </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="detailAddress">
          <el-input v-model="form.detailAddress" placeholder="请输入详细地址" :disabled="disabled" ></el-input>
        </el-form-item>
        <el-form-item label="公司电话" prop="companyPhone">
          <el-input v-model="form.companyPhone" placeholder="请输入公司电话" :disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="bankName">
          <el-input v-model="form.bankName" placeholder="请输入开户银行" maxlength="20" :disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="开户账号" prop="bankAccountNo">
          <el-input v-model="form.bankAccountNo"  placeholder="请输入开户账号" :disabled="disabled"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input v-model="form.linkMan"  placeholder="请输入联系人" maxlength="16"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="linkPhone">
          <el-input v-model="form.linkPhone"  placeholder="请输入联系方式"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input v-model="form.email"  placeholder="请输入电子邮箱"></el-input>
        </el-form-item>
        <el-form-item has-feedback label="营业执照" required>
              <div class="clearfix">
              <el-upload
                  list-type="picture-card"
                  :file-list="fileList"
                  action="#"
                  :auto-upload="false"
                  :limit='1'
                  accept=".webp,.jpg,.png"
                  :on-preview="handlePreview"
                  :before-upload="beforeUpload"
                  :on-change="handleChange"
                  :on-remove="handleRemove"
              >
                  <div>
                  <i class="el-icon-plus"></i>
                  </div>
              </el-upload>
              <el-dialog
                  :visible="previewVisible"
                  :footer="null"
                  append-to-body
                  close-on-click-modal
                  @close="handleCancel"
              >
                  <img alt="example" style="width: 100%" v-lazy="previewImage" />
              </el-dialog>
              </div>
        </el-form-item>
        <el-form-item has-feedback label="办理人身份证" required>
                    <div class="clearfix">
                      <el-upload
                        list-type="picture-card"
                        :file-list="fileList1"
                        action="#"
                        :auto-upload="false"
                        :limit='1'
                        accept=".webp,.jpg,.png"
                        :on-preview="handlePreview1"
                        :before-upload="beforeUpload1"
                        :on-change="handleChange1"
                        :on-remove="handleRemove1"
                      >
                        <div>
                          <i class="el-icon-plus"></i>
                        </div>
                      </el-upload>
                      <el-dialog
                        :visible="previewVisible1"
                        :footer="null"
                        append-to-body
                        close-on-click-modal
                        @close="handleCancel1"
                      >
                        <img alt="example" style="width: 100%" v-lazy="previewImage1" />
                      </el-dialog>
                    </div>
        </el-form-item>
        

        <el-form-item class="btn">
          <el-button type="primary" @click="submitForm()" :loading="isLoading">保存</el-button>
          <el-button @click="back" class="cancel_btn">取消</el-button>
        </el-form-item>
      </el-form>
      <span>(创建账号: {{createAccount}})</span>
    </div>
  </div>
</template>

<script>
import { regionData,CodeToText } from 'element-china-area-data'
export default {
  props:['editId'],//接收psMsg值
  data() {
     const checkPhone = (rule, value, callback) => {
      if(value) {
        var pass=false;
        const mobile = /^(((\+86)|(\+886)|(\+852)|(\+853))\s+)?((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|190)\d{8}$/;
        if(mobile.test(value)){
          pass=true;
        }
        if (!pass) {
          return callback(
              new Error("请输入正确的手机号码")
          )
        }
      }
      callback()
    }
     const checkTax = (rule, value, callback) => {
      if (value == "") {
        return callback(new Error("请输入纳税人识别号"));
      } else if (
          value.length == 15 ||
          value.length == 16 ||
          value.length == 17 ||
          value.length == 18 ||
          value.length == 19 ||
          value.length == 20
      ) {
        const pattern = /^[A-Z0-9]+$/;
        if (!pattern.test(value)) {
          return callback(new Error("纳税人识别号只能输入数字或大写英文字母"));
        }
        callback();
      } else {
        return callback(new Error("请检查纳税人识别号"));
      }
    };
    return {
      //营业执照图上传
      previewVisible: false,
      previewImage: "",
      fileList: [],
      fileData:'',
      //办理人身份证上传
      previewVisible1: false,
      previewImage1: "",
      fileList1: [],
      fileData1:'',
      disabled:true,//位选企业禁用
      createAccount:'',//创建账号
      companyList: [ //企业名称列表
            //  {label: '公司1',value: '0'},
            //  {label: '公司2',value: '1'},
            ], 
      addressOptions: regionData,
      form: {
        // 企业名称 纳税人识别号 公司地址 详细地址 公司电话 开户银行 开户账号 联系人 联系方式 电子邮箱
        // companyName taxId address detailAddress companyPhone bankName bankAccountNo linkMan linkPhone email
        companyName: '', //企业名称
        companyId: '', //企业名称
        taxId: '', //纳税人识别号
        address: '', //公司地址
        detailAddress: '', //详细地址
        companyPhone: '', //公司电话
        bankName: '', //开户银行
        bankAccountNo: '', //开户账号
        linkMan: '', //联系人
        linkPhone: '',  //联系方式
        email: '',  //电子邮箱
      },
      rules: {
        companyName: [{ required: true, message: '请输入用户名称', trigger: 'blur' },],
        taxId: [{ required: true, message: '纳税人识别号'},{ validator: checkTax, trigger: 'blur' }],
        address: [{ required: true, message: '请输入公司地址', trigger: 'blur' },],
        detailAddress: [{ required: true, message: '请输入详细地址', trigger: 'blur' },],
        companyPhone: [{ required: true, message: '请输入公司电话', trigger: 'blur' },],
        bankName: [{ required: true, message: '请选择开户银行', trigger: 'blur' },],
        bankAccountNo: [{ required: true, message: '请输入开户账号', trigger: 'blur' },],
        linkMan: [{ required: true, message: '请输入联系人', trigger: "blur" }],
        linkPhone: [{ required: true, message: '请输入联系方式',trigger: "blur"},{ validator: checkPhone, trigger: 'blur' }],
        email: [{ required: true, message: '请输入电子邮箱', trigger: "blur" },
        { pattern: '^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$', message: '账户不合法，请输入正确的账户邮箱', trigger: 'blur' },
        ],
      },
      certificateTypeOptions:[{'key': 'RSA1024','value': '1'},{'key': 'RSA1024','value': '2'},{'key': 'RSA2048','value': '3'},{'key': 'SM2','value': '4'}],
      isLoading: false,

    }
  },

  methods: {
 //省市联动
      addressChange(arr) {
        this.form.address = arr[0] +','+ arr[1] +','+ arr[2]
      },
//营业执照图上传
      handleCancel() {
        this.previewVisible = false;
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file);
        }
        this.previewImage = file.preview || file.url;
        this.previewVisible = true;
      },
      handleChange(file,fileList) {
        this.fileList = []
        this.fileList.push(file)
        // this.fileList = [...this.fileList, file];
        // this.hideUpload = fileList.length >= this.limitCount;
      },
      handleRemove(file, fileList) {
        
        console.log(file,'1111', fileList);
        this.fileList = []
        // this.hideUpload = fileList.length >= this.limitCount;
      },
      beforeUpload(file,fileList) {
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt1M = file.size / 1024 / 1024  < 1;
        if(!isJPG) {
            this.$message.error("请上传png/jpg 格式文件");
            this.fileList = [];
            return false
        }else if (!isLt1M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            this.fileList = [];
            return false
        }
      },
 //办理人身份证上传
      handleCancel1() {
        this.previewVisible1 = false;
      },
      async handlePreview1(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file);
        }
        this.previewImage1 = file.preview || file.url;
        this.previewVisible1 = true;
      },
      handleChange1(file,fileList1) {
        this.fileList1 = []
        console.log(file,'file55555555')
        this.fileList1.push(file)
      },
      handleRemove1(file, fileList1) {
        console.log(file, '222', fileList1);
        this.fileList1 = []
      },
      beforeUpload1(file,fileList1) {
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        const isLt1M = file.size / 1024 / 1024 < 1;
        if(!isJPG) {
            this.$message.error("请上传png/jpg 格式文件");
            this.fileList1 = [];
            return false
        }else if (!isLt1M) {
            this.$message.error("上传文件大小不能超过 1MB!");
            this.fileList1 = [];
            return false
        }
      },

//接入企业单个查询
init(){  //初始化内容
 		this.isLoading = true;
    let data = {id:this.editId, };
    console.log(data,'datadatadatadata')
    this.$store.dispatch("findById", data).then(res => {
            this.isLoading = false;
           if (res.success) {
              this.disabled = false;
              this.form = res.data;
              this.form.address = res.data.address.split(',')
              this.form.companyId = res.data.id;
              this.createAccount = res.data.createAccount;
              this.fileList = [{name:'营业执照',url:res.data.licenseImg}]
              // this.fileData = res.data.licenseImg;
              this.fileList1 = [{name:'办理人身份证',url:res.data.idcardImg}]
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
          })
     
      
 },

//CA证书接入企业查询
 getJoinCompany(){  //初始化内容
 		this.isLoading = true;
    let data = {userId: this.getToken('userId'),};
    this.$store.dispatch("findCompanyList", data).then(res => {
            this.isLoading = false;
           if (res.success) {
             this.companyList = res.data;
              // console.log('获取企业表',res.data)
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
          })
  },

// 企业名称切换
  selectCompany(value) {
    console.log(value,'企业名称切换',)
    let item = this.companyList.find((items) => { return items.id == value }) 
    console.log(item,'企业名称切换item')
    this.createAccount = item.createAccount;
    this.form.taxId = item.taxId; 
    if(item.address){
      this.form.address = item.address.split(',');
    }
    this.form.detailAddress = item.detailAddress;
    this.form.companyPhone = item.phone;
    this.form.bankName = item.bankName;
    this.form.bankAccountNo = item.bankAccountNo;
    this.form.companyName = item.name;
    this.form.linkMan = '';
    this.form.linkPhone = '';
    this.form.email = '';
    this.fileList = [];
    this.fileList1 = [];
    if(value){
        this.disabled = false;
    }
  
    },
  
 // 保存
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
           const formData = new FormData();
           console.log(this.fileList1,'this.fileList')
          if (this.fileList.length == 0) {
            this.$message.error('请选择上传营业执照')
            return;
          }
          if (this.fileList1.length == 0) {
            this.$message.error('请选择上传办理人身份证')
            return;
          }
          this.fileList.forEach((file) => {
              if(file.raw){
                formData.append("licenseFile", file.raw);
               }else{
                formData.append("licenseImg", file.url);
               }
              });
          this.fileList1.forEach((file) => {
               if(file.raw){
                formData.append("idCardFile", file.raw);
               }else{
                formData.append("idCardImg", file.url);
               }
               
              });
          //  console.log(formData,'formData')
          formData.append("id", this.form.companyId);
          formData.append("taxId", this.form.taxId);
          formData.append("address", this.form.address);
          formData.append("detailAddress", this.form.detailAddress);
          formData.append("companyPhone", this.form.companyPhone);
          formData.append("bankName", this.form.bankName);
          formData.append("bankAccountNo", this.form.bankAccountNo);
          formData.append("linkMan", this.form.linkMan);
          formData.append("linkPhone", this.form.linkPhone);
          formData.append("email", this.form.email);
          formData.append("userId", this.getToken('userId'));
          formData.append("companyName", this.form.companyName);
          formData.append("createAccount", this.createAccount);
          // let data = {...this.form, type: this.$route.query.category, userId: this.getToken('userId'),  };
          this.$store.dispatch("editJoinCompany", formData).then(res => {
            this.isLoading = true;
            if (res.success) {
              this.isLoading = false;
              this.$emit('change', true) 
            } else {
              this.$message.error(res.msg)
              this.isLoading = false
            }
          }).catch(err => {
            this.isLoading = false
          })
        }
      });
    },
    back() {
      this.$emit('change', true) 
    },

   
  },

  mounted() {
    // this.accountCapitalName();
    this.init();
    this.getJoinCompany();
  }

}
</script>

<style lang="less" scoped>
 .edit_insurance {
  // height: calc(100% - 60px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    font-weight: 700;
    color: #333333;
    font-size: 18px;
    padding: 18px 0;
    width: calc(100% - 40px);
    border-bottom: 1px solid #dcdcdc;
  }
  .ruleForm {
    margin: 40px auto;
    .el-form-item__content {
      .el-input__inner {
        width: 420px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      .el-input.is-disabled {
        .el-input__inner {
          background-color: #f6f6f6;
        }
      }
    }

    .btn {
      text-align: center;
      .el-button {
        width: 100px;
      }
      .cancel_btn {
        margin-left: 20px;
      }
    }
  }
}
.el-form-item__content {
  text-align: center !important;
}
/deep/ .el-cascader{
  width: 100%;
}
/deep/ .el-select{
  width: 100%;
}
.desCla{
  display: flex;
  span{
    margin-top: 50px;
  }
}
</style>