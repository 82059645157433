<!--
 * @Author: fangting fangting@whty.com.cn
 * @Date: 2022-12-08 14:27:02
 * @LastEditors: fangting fangting@whty.com.cn
 * @LastEditTime: 2023-01-03 15:45:42
 * @FilePath: \web\src\views\enterprise\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="app-container">
    <transition name="fade-transform" mode="out-in">
      <div v-if="isShow=='1'" class="navbar">
        <span>接入企业管理</span>
      </div>
      <div v-if="isShow=='2'" class="navbar">
        <span class="taxDeclaration" @click='changeShow'>接入企业管理</span><i class="el-breadcrumb__separator el-icon-arrow-right"></i><span class="taxAdd">新增</span>
      </div>
      <div v-if="isShow=='3'" class="navbar">
        <span class="taxDeclaration" @click='changeShow'>接入企业管理</span><i class="el-breadcrumb__separator el-icon-arrow-right"></i><span class="taxAdd">编辑</span>
      </div>
    </transition>
    <transition name="fade-transform" mode="out-in">
      <access-enterprise ref="accessEnterprise" @change='change' v-if="isShow=='1'"/>
      <add-enterprise ref="taxAdd" @change='change' v-if="isShow=='2'"/>
      <edit-enterprise ref="taxEdit" :editId='editId' @change='change' v-if="isShow=='3'"/>
    </transition>
  </div>
</template>

<script>
  import accessEnterprise from './modules/accessEnterprise'
  import addEnterprise from './modules/addEnterprise'
  import editEnterprise from './modules/editEnterprise'
  export default {
    components: {accessEnterprise,addEnterprise,editEnterprise},
    data() {
      return {
        isShow : '1',
        editId:'',
      }
    },
    methods: {
      change(val,val2){
        console.log(val,'1111',val2)
        this.isShow = val;
        this.editId = val2;
      },
      changeShow(){
        // this.isShow = !this.isShow;
        this.isShow = '1';
      }
    },
  }
</script>

<style lang='less' scoped>
  .app-container {
      width: 100%;
      // height: 100% !important;
  }
  .navbar{
    height: 1.35rem;
    line-height: 1.35rem;
    margin-bottom: 0.52rem;
    border: none !important;
    color: #222;
    font-size: 0.83rem;
    cursor: text;
  }

  .taxDeclaration{
    color: #303133;
  }
  .taxDeclaration:hover{
    color: #4d70ff;
    cursor: pointer;
  }
  .taxAdd{
    color: #222;
  }
</style>